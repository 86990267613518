import React, { useCallback, useEffect, useState } from 'react';
import { AspectRatio, Box, Skeleton } from '@chakra-ui/react';
import { imageURL } from '@theark/react-common';
import fallBackLogo from '../../assets/images/logo.png';
import ImageWithFallback from '../ImageWithFallback';
import dynamic from 'next/dynamic';

interface RenderNftAssetProps {
	animation_url?: string;
	image_url?: string;
	isImageFullHeight?: boolean;
	imageAlt?: string;
	onClick?: () => void;
	fullAsset?: boolean;
	sx?: any;
	quality?: number;
}

// Only render on client-side to avoid hydration issues
const RenderNftAsset: React.FC<RenderNftAssetProps> = ({
	animation_url,
	image_url,
	isImageFullHeight,
	imageAlt,
	onClick,
	fullAsset,
	sx,
	quality = 60,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [mediaUrl, setMediaUrl] = useState<string>('');
	const [isContextMenuDisabled, setIsContextMenuDisabled] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		// Mark component as mounted (client-side only)
		setIsMounted(true);

		const loadMedia = async () => {
			setIsLoading(true);
			if (animation_url) {
				setMediaUrl(imageURL(animation_url));
			} else if (image_url) {
				setMediaUrl(imageURL(image_url));
			} else {
				setMediaUrl(fallBackLogo.src);
			}
			setIsLoading(false);
		};

		loadMedia();
	}, [animation_url, image_url]);

	const handleContextMenu = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setIsContextMenuDisabled(true);
		setTimeout(() => setIsContextMenuDisabled(false), 1000);
	}, []);

	const handleImageDragStart = useCallback((e: React.DragEvent) => {
		e.preventDefault();
	}, []);

	// Don't render anything during SSR
	if (typeof window === 'undefined' || !isMounted) {
		return <Skeleton height={isImageFullHeight ? '300px' : '268px'} width={'100%'} fadeDuration={0} />;
	}

	return (
		<Skeleton isLoaded={!isLoading} fadeDuration={1}>
			{animation_url ? (
				<AspectRatio maxW={fullAsset ? '100%' : '390px'} ratio={1} sx={sx}>
					<video
						key={mediaUrl} // Add key to force remount on URL change
						autoPlay
						loop
						muted={!fullAsset}
						preload="auto"
						playsInline
						webkit-playsinline="true"
						x5-playsinline="true"
						controls={fullAsset}
						controlsList="nodownload"
						className={fullAsset ? '' : !isImageFullHeight ? 'nftVideo' : ''}
						src={mediaUrl}
						onClick={onClick}
						onLoadedData={() => setIsLoading(false)}
					/>
				</AspectRatio>
			) : (
				<Box
					position="relative"
					onContextMenu={handleContextMenu}
					style={{
						WebkitTouchCallout: 'none',
						WebkitUserSelect: 'none',
						cursor: isContextMenuDisabled ? 'not-allowed' : 'pointer',
					}}
				>
					<ImageWithFallback
						key={mediaUrl} // Add key to force remount on URL change
						src={mediaUrl || fallBackLogo.src} // Ensure we always have a src value
						fallbackSrc={fallBackLogo.src}
						blurDataURL={fallBackLogo.blurDataURL}
						placeholder="blur"
						alt={imageAlt ?? 'NFT Image'}
						height={isImageFullHeight ? 300 : 268}
						width={fullAsset ? 1080 : 390}
						priority={!fullAsset} // Add priority for initial view
						onError={(e) => {
							(e.target as HTMLImageElement).src = fallBackLogo.src;
						}}
						onClick={onClick}
						onDragStart={handleImageDragStart}
						onLoad={() => setIsLoading(false)}
						style={{
							height: isImageFullHeight ? '100%' : 300,
							width: '100%',
							objectFit: 'cover',
							pointerEvents: isContextMenuDisabled ? 'none' : 'auto',
							...(sx && sx),
						}}
						quality={quality}
					/>
					{isContextMenuDisabled && (
						<Box
							position="absolute"
							top="50%"
							left="50%"
							transform="translate(-50%, -50%)"
							bg="rgba(0,0,0,0.7)"
							color="white"
							padding="2"
							borderRadius="md"
						>
							Saving is disabled
						</Box>
					)}
				</Box>
			)}
		</Skeleton>
	);
};

// Create a client-side only version of the component
const ClientOnlyRenderNftAsset = dynamic(() => Promise.resolve(RenderNftAsset), {
	ssr: false,
});

export { ClientOnlyRenderNftAsset as RenderNftAsset };
